<template>
  <div>
    <master-component :config="config"></master-component>
  </div>
</template>


<script>
import * as dropdown from "./../js/dropdown-service";
import { UserRole, InputType } from "./../js/constants";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";

export default {
  data() {
    return {};
  },
  created() {
    const config = this.config;
  },
  computed: {
    config() {
      return {
        name: "master-document-type",
        shortName: "Document Type",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection
      };
    },
    urlSection() {
      let searchUrl = "/documentType/search";
      searchUrl = getFakeUrl(fakeAbbr.documentType.search) + searchUrl;

      let getUrl = "/documentType";
      getUrl = getFakeUrl(fakeAbbr.documentType.get) + getUrl;

      let addUrl = "/documentType";
      addUrl = getFakeUrl(fakeAbbr.documentType.create) + addUrl;

      let editUrl = "/documentType";
      editUrl = getFakeUrl(fakeAbbr.documentType.update) + editUrl;

      let removeUrl = "/documentType";
      removeUrl = getFakeUrl(fakeAbbr.documentType.remove) + removeUrl;

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text
            },
            {
              type: InputType.offset
            },
            {
              type: InputType.offset
            }
          ]
        ]
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        edit: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        remove: [UserRole.ADMIN]
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "Name"
          }
        ]
      };
    },
    formSection() {
      return {
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              rule: {
                required: true
              }
            },
            { type: InputType.offset },
            { type: InputType.offset }
          ],
          [
            {
              name: "remark",
              text: "Remark",
              type: InputType.textArea
            },
            {
              type: InputType.offset
            }
          ]
        ]
      };
    }
  }
};
</script>
